import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect, useRef } from 'react';
import { LazyHeader } from './components/Header/index.lazy';
import { LazyBanner } from './components/Banner/index.lazy';
import { LazyAbout } from './components/About/index.lazy';
import { LazyInformation } from './components/Information/index.lazy';
import { LazyLocation } from './components/Location/index.lazy';
import { LazyConveniences } from './components/Conveniences/index.lazy';
import { LazyInterior } from './components/Interior/index.lazy';
import { LazyRoomsLayout } from './components/RoomLayouts/index.lazy';
import { LazyQuestions } from './components/Questions/index.lazy';
import { LazyContacts } from './components/Contacts/index.lazy';
import { LazyFooter } from './components/Footer/index.lazy';
import FadeInWithScroll from './ui-kit/FadeInWithScroll';
import styles from './app.css';
var App = function () {
    var ref = useRef(null);
    var aboutRef = useRef(null);
    var roomsLayoutRef = useRef(null);
    var questionsRef = useRef(null);
    var contactsRef = useRef(null);
    useEffect(function () {
        window.scrollTo(0, 0); // Прокрутка к верху страницы при загрузке
    }, []);
    return (_jsxs("div", { className: styles.app, ref: ref, children: [_jsx(Suspense, { fallback: 'Loading...', children: _jsx(LazyHeader, { about: aboutRef, roomsLayout: roomsLayoutRef, questions: questionsRef, contacts: contactsRef }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(LazyBanner, {}) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyAbout, { ref: aboutRef }) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyInformation, {}) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyLocation, {}) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyConveniences, {}) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyInterior, {}) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyRoomsLayout, { ref: roomsLayoutRef }) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyQuestions, { ref: questionsRef }) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(FadeInWithScroll, { children: _jsx(LazyContacts, { ref: contactsRef }) }) }), _jsx(Suspense, { fallback: 'Loading...', children: _jsx(LazyFooter, { about: aboutRef, roomsLayout: roomsLayoutRef, questions: questionsRef, contacts: contactsRef }) })] }));
};
export default App;
